@import "variables";

html {
  scroll-behavior: smooth;
}

body {
  @include scrollbars(0.4rem, 0, $primary-color, $bg-1);
}

html,
body {
  padding: 0;
  margin: 0;
  color: $font-color;
  font-family: $font-family !important
}

a {
  color: inherit;
  text-decoration: none;
  @include trans;
}

* {
  box-sizing: border-box;
}

// h1,
// h2,
// h3,
// h4 {
//   font-weight: 700 !important;
//   line-height: 140% !important;
// }

// h2 {
//   font-size: 2.5rem !important;
// }
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500 !important;
  line-height: 160% !important;

  &.heading {
    color: $primary-color;
  }
}

p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: $font-color;

  strong {
    font-weight: 600;
  }
}

.genCon {
  ul {
    list-style: none;
    padding: 0 0 0 2rem;

    li {
      font-size: 1.2rem;
      margin: 0 0 1rem;
      @include flex;

      &:before {
        content: '';
        width: 0;
        height: 0;
        margin: 0.5rem 1rem 0 0;
        border-style: solid;
        border-width: 6.5px 0 6.5px 10px;
        border-color: transparent transparent transparent $primary-color;
      }
    }
  }

  blockquote {
    padding: 0 0 2rem 2rem;
    margin: 0 0 0 2rem;
    border-left: solid 0.5rem $primary-color;
  }
}

a.cursor-pointer {
  text-decoration: none;
  cursor: pointer !important;

  &:hover {
    cursor: pointer !important;
  }
}

.text-white {

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: #fff;
  }
}

.stackTitle {
  display: flex;
  flex-direction: column-reverse;
  margin: 0 0 1.8rem;

  h3 {
    font-size: 1rem;
    color: $primary-color;
    text-transform: uppercase;
    margin: 0 0 0.6rem;
    font-weight: 500 !important;
  }
}

.primaryBtn {
  @include primaryBtn;

  &.tertiary-color {
    background: $tertiary-color;
    border-color: $tertiary-color;
  }

  &.secondary-color {
    background: $secondary-color;
    border-color: $secondary-color;
  }

  &-outline-white {
    @include primaryBtn;
    background: transparent;
    border: 1px solid $base-white-color;

    &:hover,
    &:focus,
    &:active {
      color: $base-white-color;
    }

    &.left-arrow {
      padding: 0.6rem 0.8rem 0.6rem 2.5rem;

      &:after {
        // @include transform(rotate(-180deg));
        rotate: 180deg;
        right: auto;
        left: 5px;

      }

      &:hover,
      &:focus,
      &:active {
        &:after {
          animation: aniArrow01 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;

        }
      }
    }

    &.no-arrow {
      padding: 0.6rem 0.8rem 0.6rem 0.8rem;

    }

    &.no-cursor {
      cursor: inherit;
    }
  }

  @media (max-width: $breakpoint-sm) {
    &.vAll {
      &:after {
        margin: 0;
      }

      span {
        display: none;
      }
    }
  }
}

.secondaryBtn {
  @include secondaryBtn;
}

.image-container {
  width: 100%;

  >div {
    position: unset !important;
  }

  .image {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
}

.unset_img {
  width: 100%;
  height: 100%;

  >span {
    position: unset !important;
  }

  .custom_img {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
}

.socialLinks {
  >a {
    background: $bg-3;
    width: 3rem;
    height: 3rem;
    @include flex;
    @include align-items(center);
    @include justify-content(center);
  }

  .li {
    background: $linkedin;
  }
}

.card {
  border-radius: 0 !important;
  border: none !important;
}

.footer {
  padding: 4rem 0;

  .quakeLogo {
    width: 10rem;
    display: block;

    svg {
      fill: $bg-2;
      @include transition(all, 0.2s, ease);
    }

    &:hover svg {
      fill: $bg-4;
    }
  }

  nav {
    a {
      color: $bg-2;
      margin: 0 1rem 0.5rem 0;
    }
  }

  @media (max-width: $breakpoint-sm) {
    nav {
      flex-direction: column;
    }
  }
}

#fourohfour {
  padding: 30vh 10vw;

  h1 {
    line-height: 1 !important;

    span {
      opacity: 0;
      animation: slide-up 1s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      animation-delay: 0.3s;

      &.wrapper {
        font-size: 15rem;
        font-weight: 100;
        background: linear-gradient(45deg, $primary-color 0%, lighten($primary-color, 50) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation-delay: 0.1s;
      }
    }
  }

  aside {
    opacity: 0;
    animation: fade-in 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;

    a {
      color: $primary-color;
    }
  }
}

.slideUp {
  opacity: 0;
  animation: slide-up 1s;
  animation-delay: 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  &:nth-of-type(2) {
    animation-delay: 0.6s;
  }

  &:nth-of-type(3) {
    animation-delay: 0.9s;
  }

  &:nth-of-type(4) {
    animation-delay: 0.9s;
  }
}

.slideDelay {
  animation-delay: 0.4s;
}

.fadeIn {
  opacity: 0;
  animation: fade-in 0.5s;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.minusTop {
  opacity: 0;
  animation: minus-top 0.5s;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes expand-hor {
  0% {
    width: 0;
  }

  100% {
    width: calc(102% + 0.8rem);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slide-up {
  0% {
    transform: translate3d(0, 30px, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes minus-top {
  0% {
    transform: translate3d(0, -2rem, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, -4rem, 0);
    opacity: 1;
  }
}

@include keyframes(banner-scale-in) {
  0% {
    @include transform(scale(1.2));
  }

  100% {
    @include transform(scale(1));
  }
}


.bg-light {
  background: $bg-light;
}

.z-index-1 {
  z-index: 1;
}

@keyframes aniArrow01 {
  0% {
    transform: translateX(-30px);
  }

  100% {
    transform: translateX(35px);
  }
}

// @keyframes aniArrow02 {
//   0% {
//     transform: translateX(30px);
//   }

//   100% {
//     transform: translateX(-35px);
//   }
// }

.formio-component {
  label {
    &.field-required {
      &:after {
        content: "*";
        color: red;
      }
    }
  }
}