@import "variables";

.latestNews {
  .item {
    @include transition(all, 0.5s, ease);
    border: solid 1px #DEE8F4 !important;
    margin: 0 0 30px;

    .card-img-wrapper {
      min-height: 15rem;

      .card-img {
        object-fit: cover;
        position: absolute;
      }
    }

    .card-body {
      padding: 2rem 1.5rem;

      .card-title {
        min-height: 5rem;
        font-size: 1.2rem;
        line-height: 1.5;
      }
    }

    .primaryBtn {
      padding: 0.3rem 0.8rem;
    }

    &:hover {
      transform: translate3d(0, -0.5rem, 0);
    }
  }
}

.no-clip {
  clip-path: none !important;
  padding-bottom: 5rem !important;
  animation: none !important;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  10% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-15px);
  }

  50% {
    transform: translateY(0);
  }

  57% {
    transform: translateY(-5px);
  }

  64% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}

.formio-component {
  .fa {
    display: inline-block;
    margin-right: 0.3125rem;
    background-size: contain;
  }

  .fa-cloud-upload {
    background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z"></path></svg>');
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: bottom;
  }

  .fa-remove {
    cursor: pointer;
    background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path></svg>');
    width: 1.2rem;
    height: 1.2rem;
    vertical-align: sub;
  }
}