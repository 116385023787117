@import "styles/variables";

.root {
    margin: 0 -5px;

    :global {
        a {
            padding: 0 0.7rem;
            ;

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }

            svg {
                width: 100%;
                height: auto;
                max-width: 18px;
            }
        }

        .social-links-wrapper {
            padding: 0.8rem;
            background: rgba($base-white-color, 0.1);
            @include border-radius(7px);
            width: 200px;

           
        }
    }

    &:global.footer-section {
        &.social-links {
            @include flex;
            @include justify-content(flex-end);

            .wrapper {
                width: 200px;
            }

            // .wrapper {
            //     @include flex;
            //     @include justify-content(flex-end);
            // }
            @media (max-width: 991px) {
                margin-top: 4rem;
                @include justify-content(center);

            }
        }
    }
}