@import "styles/variables";

.root {
    background: $base-white-color;

    :global {
        margin-top: 3rem;

        .circle {
            @include border-radius(100px);

            &:nth-of-type(1) {
                right: 55px;
                top: -100px;
                width: 90px;
                height: 90px;
                background: #5ebfe0;
            }

            &:nth-of-type(2) {
                top: 5px;
                right: 75px;
                width: 35px;
                height: 35px;
                background: $navy-blue;
                ;
            }

            &:nth-of-type(3) {
                top: -10px;
                right: 0;
                width: 60px;
                height: 60px;
                background: $base-white-color;
            }
        }

        a {
            color: $base-white-color;

            &:hover,
            &:focus,
            &:active {
                color: rgba($base-white-color, 0.5);
            }
        }

        .main {
            >.wrapper {
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
            }

        }

        .wrapper {
            background: $primary-color;
            color: $base-white-color;
        }

        .navigation {
            .nav-item {
                &:not(:last-child) {
                    margin-bottom: 0.5rem;
                }

                a {
                    font-weight: 300;
                }
            }
        }

        .copyrights {
            .wrapper {
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                border-top: 1px solid rgba($base-white-color, 0.3);
            }

            p {
                font-weight: 200;
            }

            .nav {
                .nav-item {
                    padding: 0 1rem 0.5rem 0;

                    a {
                        font-weight: 200;
                    }
                }
            }
        }

        .logo {
            svg {
                width: 100%;
                height: 60px;
                max-width: 320px;
                position: relative;
                z-index: 1;
                // path {
                //     fill: $base-white-color;
                // }
            }

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }
        }

        .sourceflow {
            svg {
                margin-left: 0.5rem;
                width: 100%;
                height: auto;
                max-width: 150px;
                @include trans;

                &:hover,
                &:focus,
                &:active {
                    opacity: 0.5;
                }
            }
        }

        @media (max-width: $breakpoint-sm) {
            margin-top: 8rem;

            .circle-wrapper {
                top: -115px;
            }

            .copyrights {
                .nav {
                    .nav-item {
                        width: 100%;
                    }
                }
            }

            .navigation {
                .fs-5 {
                    font-size: 1rem !important;
                }
            }

            .lead-text {
                &.fs-4 {
                    font-size: 1.2rem !important
                }
            }
        }
    }
}